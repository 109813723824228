<template>
    <div class="uploadVoucher-wrap">
        <van-nav-bar
            title="上传凭证"
            left-arrow
            :border="false"
            @click-left="onClickLeft"
        >
            <template #right>
                <a :href="serviceUrl" target="_blank"><van-icon :name="themeImgs.commonServiceIcon" /></a>
            </template>
        </van-nav-bar>
        <div class="uploadVoucher-main-wrap">
            <div class="upload-box">
                <van-uploader v-model="voucherFileList" :max-count="1" :before-read="beforeUpload" :after-read="afterUploadVoucher" :preview-options={closeable:true} />
                <span>上传支付凭证</span>
            </div>
            <div class="start-btn-box">
                <van-button block @click="handleexchangeInfoByBuy">我已转账</van-button>
            </div>
        </div>
        <van-overlay class-name="uploadVoucher-overlay" :show="showOverlay">
            <van-loading size="24px" type="spinner" color="#fff" vertical>上传中...</van-loading>
        </van-overlay>
        <van-dialog v-model:show="exchangeInfoByBuy" title="温馨提示" :before-close="handleUploadPaymentVoucher">
            <div class="pay-desc" v-html="hintMsg.exchangeInfoByBuy">
            </div>
        </van-dialog>
    </div>
</template>

<script>
import { onMounted, ref,computed } from 'vue'
import {uploadPaymentVoucher,uploadImage,comfirmBuyerTransfer, getHint} from '@/api/api'
import { useRoute,useRouter } from 'vue-router'
import { Toast, Dialog } from 'vant'
import { useStore } from 'vuex'

export default {
    setup () {
        const $route = useRoute()
        const $router = useRouter()
        const store = useStore()
        // 点击导航栏左侧返回键/取消按钮
        const onClickLeft = () => {
            history.back()
        }
        const hintMsg = ref({})
        const handleGetHint = () => {
            getHint().then(res => {
                if(res.result == 200000) {
                    hintMsg.value = res.data
                }
            })
        }
         onMounted(() => {
            handleGetHint()
        })
        // 定义在线客服地址
        const serviceUrl = ref(store.state.sysConfig.manage)
        // 是否显示蒙层
        const showOverlay = ref(false)
        // 存储上传买家付款凭证后得到的图片地址
        const voucherImgUrl = ref('')
        // 存储上传买家付款凭证后得到的文件流
        const voucherFileList = ref([])
        // 上传买家付款凭证前
        const beforeUpload = () => {
            showOverlay.value = true
            return true
        }
        // 上传买家付款凭证后
        const afterUploadVoucher = (file) => {
            // 此时可以自行将文件上传至服务器
            const formData = new FormData()
            formData.append('file', file.file)
            formData.append('file_name', 'certificate')
            uploadImage(formData).then(res => {
                if(res.result == 200000) {
                    voucherImgUrl.value = res.data.path
                    showOverlay.value = false
                } else {
                    showOverlay.value = false
                    Toast.fail(res.msg)
                    voucherImgUrl.value = ''
                    voucherFileList.value = []
                }
            })
        };
        // 确定上传凭证
        const exchangeInfoByBuy = ref(false)
        const handleexchangeInfoByBuy = () => {
            if (hintMsg.value.exchangeInfoByBuy) {
                exchangeInfoByBuy.value = true
            } else {
                handleUploadPaymentVoucher('confirm')
            }
        }
        const handleUploadPaymentVoucher = (action) => {
          // 点击了确定按钮
          if (action === "confirm") {
                uploadPaymentVoucher({order_sn: $route.query.order_sn,pay_pic:voucherImgUrl.value}).then(res=> {
                    if(res.result == 200000) {
                        handleComfirmBuyerTransfer()
                        Toast.success('上传成功')
                        $router.push({
                            path: 'buyerOrderDetails',
                            query: {
                                order_sn: $route.query.order_sn
                            }
                        })
                    store.commit('setUntreatedNum', 0)
                    } else {
                        Toast.fail(res.msg)
                    }
                })
            exchangeInfoByBuy.value = false
          }
          // 点击了取消按钮
          else {
            exchangeInfoByBuy.value = false
          }
        }
        // 买家确认转账
        const handleComfirmBuyerTransfer = () => {
            comfirmBuyerTransfer({order_sn: $route.query.order_sn})
        }
        // mapstate--皮肤对应的图片
        const themeImgs = computed(() => store.state.themeImgs)
        return {
            onClickLeft,
            voucherFileList,
            afterUploadVoucher,
            handleUploadPaymentVoucher,
            exchangeInfoByBuy,
            handleexchangeInfoByBuy,
            showOverlay,
            hintMsg,
            beforeUpload,
            serviceUrl,
            themeImgs
        }
    }
}
</script>

<style lang="less" scoped>
.uploadVoucher-wrap {
    :deep(.van-nav-bar) {
        .van-nav-bar__right {
            .van-icon {
                width: 24px;
                height: 24px;
                .van-icon__image {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
    .uploadVoucher-main-wrap {
        height: calc(100vh - 46px);
        padding: 16px 16px 0;
        background: var(--mainBgColor);
        .upload-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 143px;
            background: var(--btnBgColor);
            border-radius: 8px;
            :deep(.van-uploader) {
                .van-uploader__upload {
                    width: 72px;
                    height: 72px;
                    background: rgba(255, 255, 255, 0.3);
                    border-radius: 50%;
                    .van-uploader__upload-icon {
                        color: #fff;
                    }
                }
                .van-uploader__preview-image {
                    width: 72px;
                    height: 72px;
                }
            }
            span {
                font-weight: 500;
                font-size: 18px;
                color: var(--textColor1);
            }
        }
        .start-btn-box {
            margin-top: 32px;
            border-radius: 8px;
            .van-button {
                border-radius: 8px;
            }
        }
    }
    .uploadVoucher-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    :deep(.van-dialog) {
        &.code-dialog {
            .van-dialog__header {
                background: var(--orderDialogHeaderBgColor);
            }
            .van-dialog__confirm {
                background: var(--orderDialogHeaderBgColor);
                color: var(--orderDialogTextColor);
            }
        }
        .van-dialog__header {
            padding: 21px 0;
            font-weight: 500;
            font-size: 18px;
            color: var(--orderDialogTextColor);
        }
        .code-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 275px;
            background-size: 100% 100% !important;
            .van-image {
                width: 158px;
                height: 158px;
                border-radius: 8px;
            }
            .name {
                margin-top: 20px;
                font-size: 16px;
                color: rgba(83, 92, 102);
            }
        }
        .cancel-order-dialog-cell {
            background: none;
            textarea::-webkit-input-placeholder {
                color: rgba(255, 255, 255, 0.6);
            }
            textarea:-ms-input-placeholder {
                color: rgba(255, 255, 255, 0.6);
            }
            textarea:-moz-placeholder {
                color: rgba(255, 255, 255, 0.6);
                opacity: 1;
            }
            textarea::-moz-placeholder {
                color: rgba(255, 255, 255, 0.6);
                opacity: 1;
            }
            .van-field__label {
                color: #fff;
            }
            .van-field__control {
                color: #fff;
            }
        }
    }
}
.pay-desc{
  color: #ffffff;
  padding:0px 5% 5% 5%;
}
</style>
